export const schema = {
  type: "object",
  properties: {
    salutation: { type: "string" },
    firstname: { type: "string" },
    lastname: { type: "string" },
    accountname: { type: "string" },
    title: { type: "string" },
    reportsto: { type: "string" },
    description: { type: "string" },
    contactowner: { type: "string" },
    phone: { type: "string" },
    email: { type: "string" },
    mailingstreet: { type: "string" },
    postalcode: { type: "string" },
    mailingcity: { type: "string" },
    mailingcountry: { type: "string" },
    mailingstate: { type: "string" },
  },
};
