export const GER_DEFAULT_TRANSLATIONS = {
  common: {
    rowsPerPages: "Zeilen pro Seite",
    page: "Seite",
    accountname: "Kontoname",
    edit: "Bearbeiten",
    delete: "Löschen",
    menuButton: "Menüknopf",
    filter: "Filter",
    goToFirstPage: "Erste Seite",
    goToPreviousPage: "Vorherige Seite",
    goToNextPage: "Nächste Seite",
    goToLastPage: "Letzte Seite",
    searchText: "Suche",
    accounts: "Konten",
    priceBooks: "Preislisten",
    contacts: "Kontakte",
    sales: "Vertrieb",
    products: "Produkte",
    service: "Dienst",
    leads: "Leads",
    oportunities: "Gelegenheiten",
    quickTexts: "Schnelltexte",
    cases: "Fälle",
    profilePicture: "Profilbild",
    logout: "Abmelden",
    clear: "Löschen",
    back: "Zurück",
    submit: "Absenden",
    close: "Schließen",
    apply: "Anwenden",
    add: "Erstellen",
    confirm: "Bestätigen",
    cancel: "Abbrechen",
    confirmationActionMessage: "Bitte bestätigen Sie Ihre Aktion",
    deleted: "Einheit wurde erfolgreich gelöscht",
    create: "Einheit wurde erfolgreich erstellt",
    update: "Einheit wurde erfolgreich aktualisiert",
    required: "Bitte geben Sie einen Wert an.",
    invalidFormat: "Bitte geben Sie einen Wert in einem gültigen Format an.",
    home: "Startseite",
    gerTranslations:
      "Deutsche Übersetzungen werden von KI generiert und sind möglicherweise nicht genau.",
    hasnotpermission:
      "Dieser Benutzer kann nur Preislisten und Schnelltexte löschen",
  },
  validations: {},
  accessibility: {},
  codelist: {
    Account: "Konto",
    Analyst: "Analyst",
    Advertisement: "Werbung",
    Aerospace: "Luft- und Raumfahrt",
    Apparel: "Bekleidung",
    Automobiles: "Automobile",
    "Baby Products": "Babyprodukte",
    Banking: "Bankwesen",
    "Best Case": "Bestfall",
    Books: "Bücher",
    Case: "Fall",
    Closed: "Geschlossen",
    "Closed Lost": "Verloren Geschlossen",
    "Closed Won": "Gewonnen Geschlossen",
    Commit: "Verpflichten",
    Competitor: "Konkurrent",
    Construction: "Bau",
    "Consumer Electronics": "Unterhaltungselektronik",
    Contact: "Kontakt",
    Consulting: "Beratung",
    Cosmetics: "Kosmetik",
    Customer: "Kunde",
    "Dr.": "Dr.",
    Education: "Bildung",
    Electronics: "Elektronik",
    Email: "E-Mail",
    "Employee Referral": "Mitarbeiterempfehlung",
    Energy: "Energie",
    Entertainment: "Unterhaltung",
    Escalated: "Eskaliert",
    "External Referral": "Externe Empfehlung",
    Fashion: "Mode",
    "Financial Services": "Finanzdienstleistungen",
    "Food & Beverages": "Lebensmittel & Getränke",
    Furniture: "Möbel",
    Government: "Regierung",
    "Health & Personal Care": "Gesundheit & persönliche Pflege",
    Healthcare: "Gesundheitswesen",
    High: "Hoch",
    "Home Appliances": "Haushaltsgeräte",
    Insurance: "Versicherung",
    Integrator: "Integrator",
    Jewelry: "Schmuck",
    Lead: "Führen",
    Legal: "Rechtlich",
    Logistics: "Logistik",
    Low: "Niedrig",
    Manufacturing: "Herstellung",
    Marketing: "Marketing",
    Media: "Medien",
    "Meet & Present": "Treffen & Präsentieren",
    Medium: "Mittel",
    Nurturing: "Förderung",
    Omitted: "Ausgelassen",
    Opportunity: "Gelegenheit",
    Other: "Andere",
    Partner: "Partner",
    "Pet Supplies": "Tierbedarf",
    Pharmaceutical: "Pharmazeutisch",
    Pipeline: "Pipeline",
    Press: "Presse",
    Priority: "Priorität",
    Product: "Produkt",
    Propose: "Vorschlagen",
    "Public Relations": "Öffentlichkeitsarbeit",
    Qualified: "Qualifiziert",
    Qualify: "Qualifizieren",
    "Real Estate": "Immobilien",
    Reseller: "Wiederverkäufer",
    Retail: "Einzelhandel",
    "Sporting Goods": "Sportartikel",
    Telecommunications: "Telekommunikation",
    "Tools & Hardware": "Werkzeuge & Hardware",
    Toys: "Spielzeug",
    "Trade Show": "Handelsmesse",
    Transportation: "Transport",
    "Travel & Hospitality": "Reisen & Gastfreundschaft",
    Utilities: "Dienstprogramme",
    "Waiting on Customer": "Warten auf den Kunden",
    Web: "Web",
    "Word of Mouth": "Mundpropaganda",
    Working: "Arbeiten",
    New: "Neu",
    Contracted: "Vertraglich",
    Unqualified: "Nicht qualifiziert",
    Phone: "Telefon",
    select_parentaccount: "Aus Konten auswählen",
    select_accountname: "Aus Konten auswählen",
    select_reportsto: "Aus Kontakten auswählen",
    select_contactname: "Aus Kontakten auswählen",
    leadsByStatus: "Leads nach Status",
    productsByFamily: "Produkte nach Familie",
    oportunitiesByStage: "Chancen nach Phase",
    casesByPriority: "Fälle nach Priorität",
    products: "Produkte",
    oportunities: "Chancen",
  },
  accounts: {
    accounts: "Konten",
    accountname: "Kontoname",
    website: "Webseite",
    type: "Typ",
    select_type: "Aus Konten auswählen",
    description: "Beschreibung",
    parentaccount: "Übergeordnetes Konto",
    accountowner: "Kontoinhaber",
    phone: "Telefon",
    Phone: "Telefon",
    billingstreet: "Rechnungsstraße",
    billingpostalcode: "Rechnungspostleitzahl",
    billingcity: "Rechnungsstadt",
    billingcountry: "Rechnungsland",
    billingstate: "Rechnungsbundesland",
    shippingstreet: "Lieferstraße",
    shippingpostalcode: "Lieferpostleitzahl",
    shippingcity: "Lieferstadt",
    shippingcountry: "Lieferland",
    shippingstate: "Lieferbundesland",
    about: "Titelinformation",
    billing: "Rechnungsinformationen",
    shipping: "Lieferinformationen",
  },
  products: {
    products: "Produkte",
    productname: "Produktname",
    productfamily: "Produktfamilie",
    productcode: "Produktcode",
    active: "Aktiv",
    productdescription: "Produktbeschreibung",
    details: "Produktinformation",
  },
  priceBooks: {
    details: "Preislisteninformationen",
    priceBooks: "Preislisten",
    pricebookname: "Preislistenname",
    description: "Beschreibung",
    active: "Aktiv",
    isstandard: "Ist Standard",
  },
  quickTexts: {
    details: "Schnelltextinformationen",
    quickTexts: "Schnelltexte",
    quicktextname: "Schnelltextname",
    relatedto: "Bezogen auf",
    field: "Feld",
    category: "Kategorie",
    message: "Nachricht",
  },
  contacts: {
    contacts: "Kontakte",
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    accountname: "Kontoname",
    title: "Titel",
    reportsto: "Berichtet an",
    description: "Beschreibung",
    contactowner: "Kontaktinhaber",
    phone: "Telefon",
    email: "E-Mail",
    mailingstreet: "Poststraße",
    postalcode: "Postleitzahl",
    mailingcity: "Poststadt",
    mailingcountry: "Postland",
    mailingstate: "Postbundesland",
    about: "Über",
    getInTouch: "Kontakt aufnehmen",
    mailing: "Postadresse",
  },
  leads: {
    details: "Primäre Details",
    getInTouch: "Kontakt aufnehmen",
    address: "Adresse",
    segment: "Segment",
    leads: "Leads",
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    company: "Firma",
    title: "Titel",
    website: "Webseite",
    description: "Beschreibung",
    leadstatus: "Lead-Status",
    leadowner: "Lead-Inhaber",
    phone: "Telefon",
    email: "E-Mail",
    street: "Straße",
    postalcode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    state: "Bundesland",
    employeesnumber: "Anzahl der Mitarbeiter",
    annualrevenue: "Jahresumsatz",
    leadsource: "Leadquelle",
    industry: "Branche",
  },
  oportunities: {
    oportunities: "Gelegenheiten",
    oportunityname: "Gelegenheitsname",
    accountname: "Kontoname",
    closedate: "Abschlussdatum",
    amount: "Betrag",
    description: "Beschreibung",
    oportunityowner: "Gelegenheitsinhaber",
    stage: "Stufe",
    probability: "Wahrscheinlichkeit",
    forecastcategory: "Prognosekategorie",
    nextstep: "Nächster Schritt",
    status: "Status",
    details: "Primäre Details",
  },
  cases: {
    details: "Fallinformationen",
    contactInfo: "Kontaktinformationen",
    descript: "Beschreibungsinformationen",
    cases: "Fälle",
    status: "Status",
    priority: "Priorität",
    caseorigin: "Fallursprung",
    caseowner: "Fallinhaber",
    contactname: "Kontaktname",
    accountname: "Kontoname",
    subject: "Betreff",
    description: "Beschreibung",
  },
};
