export const schema = {
  type: "object",
  properties: {
    salutation: { type: "string" },
    firstname: { type: "string" },
    lastname: { type: "string" },
    company: { type: "string" },
    title: { type: "string" },
    website: { type: "string" },
    description: { type: "string" },
    leadstatus: { type: "string" },
    leadowner: { type: "string" },
    phone: { type: "string" },
    email: { type: "string" },
    street: { type: "string" },
    postalcode: { type: "string" },
    city: { type: "string" },
    country: { type: "string" },
    state: { type: "string" },
    employeesnumber: { type: "string" },
    annualrevenue: { type: "string" },
    leadsource: { type: "string" },
    industry: { type: "string" },
  },
};
