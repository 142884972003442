import { ACCOUNT_TRANSLATIONS } from "../../forms/accounts/translations";
import { CASES_TRANSLATIONS } from "../../forms/cases/translations";
import { CONTACTS_TRANSLATIONS } from "../../forms/contacts/translations";
import { LEADS_TRANSLATIONS } from "../../forms/leads/translations";
import { OPORTUNITIES_TRANSLATIONS } from "../../forms/oportunities/translations";
import { PRICEBOOKS_TRANSLATIONS } from "../../forms/priceBooks/translations";
import { PRODUCTS_TRANSLATIONS } from "../../forms/products/translations";
import { QUICKTEXTS_TRANSLATIONS } from "../../forms/quickTexts/translations";

export const EN_DEFAULT_TRANSLATIONS = {
  common: {
    rowsPerPages: "Rows Per Pages",
    page: "Page",
    accountname: "Account Name",
    edit: "Edit",
    delete: "Delete",
    menuButton: "Menu Button",
    filter: "Filter",
    goToFirstPage: "First Page",
    goToPreviousPage: "Previous Page",
    goToNextPage: "Next Page",
    goToLastPage: "Last Page",
    searchText: "Search",
    accounts: "Accounts",
    priceBooks: "Price Books",
    contacts: "Contacts",
    sales: "Sales",
    service: "Service",
    products: "Products",
    leads: "Leads",
    oportunities: "Oportunities",
    quickTexts: "Quick Texts",
    cases: "Cases",
    profilePicture: "Profile Picture",
    logout: "Logout",
    clear: "Clear",
    back: "Back",
    submit: "Submit",
    close: "Close",
    apply: "Apply",
    add: "Create",
    confirm: "Confirm",
    cancel: "Cancel",
    confirmationActionMessage: "Please confirm your action",
    deleted: "Entity was successfully deleted",
    create: "Entity was successfully created",
    update: "Entity was successfully updated",
    required: "Please provide a value.",
    invalidFormat: "Please provide a value with a valid format.",
    home: "Home",
    gerTranslations:
      "German translations are AI generated and may be not accurate",
    hasnotpermission: "This user can only delete price books and quick texts",
  },
  validations: {},
  accessibility: {},
  menu: {},
  codelist: {
    select_parentaccount: "Select From Accounts",
    select_accountname: "Select From Accounts",
    select_reportsto: "Select From Contacts",
    select_contactname: "Select From Contacts",
    leadsByStatus: "Leads By Status",
    productsByFamily: "Products By Family",
    oportunitiesByStage: "Oportunities By Stage",
    casesByPriority: "Cases By Priority",
    products: "Products",
    oportunities: "Oportunities",
  },
  accounts: {
    accounts: "Accounts",
    accountname: "Account Name",
    website: "Website",
    select_type: "Select From Accounts",
    type: "Type",
    description: "Description",
    parentaccount: "Parent Account",
    accountowner: "Account Owner",
    phone: "Phone",
    billingstreet: "Billing Street",
    billingpostalcode: "Billing Postal Code",
    billingcity: "Billing City",
    billingcountry: "Billing Country",
    billingstate: "Billing State",
    shippingstreet: "Shipping Street",
    shippingpostalcode: "Shipping Postal Code",
    shippingcity: "Shipping City",
    shippingcountry: "Shipping Country",
    shippingstate: "Shipping State",
    about: "Title Information",
    billing: "Billing Information",
    shipping: "Shipping Information",
  },
  products: {
    products: "Products",
    productname: "Product Name",
    productfamily: "Product Family",
    productcode: "Product Code",
    active: "Active",
    productdescription: "Product Description",
    details: "Product Information",
  },
  priceBooks: {
    details: "Price Book Information",
    priceBooks: "Price Books",
    pricebookname: "Price Book Name",
    description: "Description",
    active: "Active",
    isstandard: "Is Standard",
  },
  quickTexts: {
    details: "Quick Text Information",
    quickTexts: "Quick Texts",
    quicktextname: "Quick Text Name",
    relatedto: "Related To",
    field: "Field",
    category: "Category",
    message: "Message",
  },
  contacts: {
    contacts: "Contacts",
    salutation: "Salutation",
    firstname: "First Name",
    lastname: "Last Name",
    accountname: "Account Name",
    title: "Title",
    reportsto: "Reports To",
    description: "Description",
    contactowner: "Contact Owner",
    phone: "Phone",
    email: "Email",
    mailingstreet: "Mailing Street",
    postalcode: "Postal Code",
    mailingcity: "Mailing City",
    mailingcountry: "Mailing Country",
    mailingstate: "Mailing State",
    about: "About",
    getInTouch: "Get In Touch",
    mailing: "Mailing Address",
  },
  leads: {
    details: "Primary Details",
    getInTouch: "Get In Touch",
    address: "Address",
    segment: "Segment",
    leads: "Leads",
    salutation: "Salutation",
    firstname: "First Name",
    lastname: "Last Name",
    company: "Company",
    title: "Title",
    website: "Website",
    description: "Description",
    leadstatus: "Lead Status",
    leadowner: "Lead Owner",
    phone: "Phone",
    email: "Email",
    street: "Street",
    postalcode: "Postal Code",
    city: "City",
    country: "Country",
    state: "State",
    employeesnumber: "Employees Number",
    annualrevenue: "Annual Revenue",
    leadsource: "Lead Source",
    industry: "Industry",
  },
  oportunities: {
    oportunities: "Oportunities",
    oportunityname: "Oportunity Name",
    accountname: "Account Name",
    closedate: "Closed Date",
    amount: "Amount",
    active: "Active",
    description: "Description",
    oportunityowner: "Oportunity Owner",
    stage: "Stage",
    probability: "Probability",
    forecastcategory: "Forecast Category",
    nextstep: "Next Step",
    status: "Status",
    details: "Primary Details",
  },
  cases: {
    details: "Case Information",
    contactInfo: "Contact Information",
    descript: "Description Information",
    cases: "Cases",
    status: "Status",
    priority: "Priority",
    caseorigin: "Case Origin",
    caseowner: "Case Owner",
    contactname: "Contact Name",
    accountname: "Account Name",
    subject: "Subject",
    description: "Description",
  },
};
